import { render } from "./UsersComponent.vue?vue&type=template&id=e79cd8f6"
import script from "./UsersComponent.vue?vue&type=script&lang=js"
export * from "./UsersComponent.vue?vue&type=script&lang=js"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "e79cd8f6"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('e79cd8f6', script)) {
    api.reload('e79cd8f6', script)
  }
  
  module.hot.accept("./UsersComponent.vue?vue&type=template&id=e79cd8f6", () => {
    api.rerender('e79cd8f6', render)
  })

}

script.__file = "src/components/UsersComponent.vue"

export default script