import { render } from "./Users.vue?vue&type=template&id=68be103e"
import script from "./Users.vue?vue&type=script&lang=js"
export * from "./Users.vue?vue&type=script&lang=js"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "68be103e"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('68be103e', script)) {
    api.reload('68be103e', script)
  }
  
  module.hot.accept("./Users.vue?vue&type=template&id=68be103e", () => {
    api.rerender('68be103e', render)
  })

}

script.__file = "src/views/Users.vue"

export default script