<template>
    <UsersComponent />
</template>

<script>
import UsersComponent from '../components/UsersComponent.vue'

export default {
  name: 'Users',
  components: {
    UsersComponent,
  },
}
</script>
