<template>
  <div>
    <BusinessHeader v-if="userHead.role === 'superadmin'"> </BusinessHeader>

    <div class="bg-white w-full py-4 px-7 border-gray-200 border-b-2">
      <button
        class="bg-gray-700 text-white py-2 px-4 flex items-center rounded-full"
        @click="openModal()"
      >
        <svg
          aria-hidden="true"
          focusable="false"
          data-prefix="fas"
          data-icon="plus"
          class="svg-inline--fa fa-plus fa-w-14 inline font-light mr-3"
          width="20px"
          role="img"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 448 512"
        >
          <path
            fill="currentColor"
            stroke-width="1%"
            d="M416 208H272V64c0-17.67-14.33-32-32-32h-32c-17.67 0-32 14.33-32 32v144H32c-17.67 0-32 14.33-32 32v32c0 17.67 14.33 32 32 32h144v144c0 17.67 14.33 32 32 32h32c17.67 0 32-14.33 32-32V304h144c17.67 0 32-14.33 32-32v-32c0-17.67-14.33-32-32-32z"
          ></path>
        </svg>
        Add User
      </button>
    </div>

    <template v-if="users.length == 0">
      <div
        class="border-gray-300 border-2 mt-3 text-center py-3 text-red-500 text-base"
      >
        No users yet
      </div>
    </template>
    <template v-else>
      <div class="flex-row inline-flex w-full py-4 px-2">
        <div class="w-1/6 ">Name</div>
        <div class="w-1/6 text-center">Role</div>
        <div class="w-1/6 text-center">Location</div>
        <div class="w-1/6 text-center">Status</div>
        <div class="w-1/6"></div>
        <div class="w-1/6"></div>
      </div>

      <div
        class="flex-row inline-flex w-full border-t-2 border-gray-200 bg-white py-4 px-2"
        v-for="user in users"
        :key="user.uid"
      >
        <div class="w-1/6 ">
          <a href="#" class="text-blue-400"> {{ user.display_name }} </a>
          <a href="#" class="text-xs block"> {{ user.username }} </a>
        </div>
        <div class="w-1/6 text-center capitalize">
          {{ trimUserRole(user.role) }}
        </div>
        <div class="w-1/6 text-center capitalize">
          {{ user.location_name }}
        </div>

        <div
          class="w-1/6 text-center capitalize"
          :class="user.status === 'active' ? 'text-green-500' : ' text-red-500'"
        >
          {{ user.status }}
        </div>
        <div
          class="w-auto text-center"
          v-if="
            (userHead.role === 'branch-manager' &&
              user.role !== 'business-admin') ||
              userHead.role !== 'branch-manager'
          "
        >
          <a href="#" class="text-blue-400" @click="editUser(user)">
            Edit
          </a>
          <a
            href="#"
            class="ml-4"
            @click="changeUserStatus(user)"
            v-if="
              userHead !== 'branch-manager' ||
                (user.role == 'business-admin' &&
                  user.role !== 'branch-manager')
            "
            :class="
              user.status === 'active' ? 'text-red-500' : ' text-green-500'
            "
          >
            {{ user.status === "active" ? "Deactivate" : "Activate" }}
          </a>
          <a
            href="#"
            v-if="
              userHead !== 'branch-manager' ||
                (user.role == 'business-admin' &&
                  user.role !== 'branch-manager')
            "
            class="text-blue-400 ml-5"
            @click="openResetPasswordModal(user)"
          >
            Reset Password
          </a>
        </div>
      </div>

      <div
        class="flex-row inline-flex w-full border-t-2 border-gray-200 bg-white py-3"
        v-if="users.length != 0"
      >
        <div class="w-1/5 text-center"></div>
        <div class="w-1/5 text-center"></div>
        <div class="w-1/5 text-center"></div>
        <div class="w-1/5 text-center"></div>
        <div class="w-1/5 text-center">{{ users.length }} Users</div>
      </div>
    </template>

    <div
      class="h-screen w-full fixed top-0 right-0 bg-gray-700 opacity-25"
      v-if="
        showPopup ||
          showEditPopup ||
          showResetPassPopup ||
          showConfirmationPopup
      "
    ></div>

    <!-- add User popup -->
    <div
      class="h-screen w-full flex items-center justify-center bg-teal-lightest fixed top-0 overflow-auto right-0 lg:business-modal-lg "
      v-if="showPopup"
    >
      <div
        class="h-screen w-full absolute flex items-center justify-center bg-modal"
      >
        <div
          class="bg-white rounded shadow pb-8 m-4 max-h-full text-center w-96"
        >
          <div class="mb-4 bg-gray-700 p-4 text-white text-base">
            Add User
          </div>
          <form @submit.prevent="addUser" class="px-4">
            <div class="items-center flex flex-col mt-7">
              <div class="flex flex-col mb-6 w-full mt-2">
                <input
                  class="border py-2 px-3 text-grey-darkest mt-1"
                  type="text"
                  placeholder="Name"
                  v-model="user.display_name"
                  name="user_displayname"
                  autocomplete="off"
                  required
                  id="user_displayname"
                />
                <input
                  class="border py-2 px-3 text-grey-darkest mt-1"
                  type="text"
                  placeholder="Username"
                  v-model="user.username"
                  name="user_username"
                  autocomplete="off"
                  required
                  id="user_username"
                />
                <input
                  class="border py-2 px-3 text-grey-darkest mt-1"
                  type="text"
                  placeholder="Password"
                  v-model="user.password"
                  minlength="6"
                  name="user_password"
                  autocomplete="off"
                  required
                  id="user_password"
                />
                <select
                  required
                  class="form-select mt-1 block w-full capitalize border-gray-200 border-2 py-2"
                  v-if="
                    userHead.role === 'superadmin' ||
                      userHead.role === 'business-admin'
                  "
                  v-model="user.location_id"
                >
                  <option disabled selected value="title">
                    Select Location</option
                  >
                  <option
                    v-for="location in locations"
                    :key="location.id"
                    :value="location.id"
                  >
                    {{ location.location_name }}
                  </option>
                </select>
                <select
                  required
                  class="form-select mt-1 block w-full  border-gray-200 border-2 py-2"
                  v-if="
                    userHead.role === 'superadmin' ||
                      userHead.role === 'business-admin'
                  "
                  v-model="user.role"
                >
                  <option disabled selected value="title"> Select Role</option>
                  <option value="business-admin">Business Admin</option>
                  <option value="branch-manager">Branch Manager</option>
                  <option value="branch-staff">Branch Staff</option>
                </select>
              </div>
            </div>
            <div role="alert" class="my-3" v-if="errorMsg != ''">
              <div
                class="border border-red-400 rounded text-center bg-red-100 text-sm px-4 py-3 text-red-700"
              >
                <p>{{ errorMsg }}.</p>
              </div>
            </div>
            <div class="flex justify-center flex-col md:flex-row px-2">
              <button
                class="flex-no-shrink w-full text-white py-2 px-4 rounded-full bg-gray-700 md:w-40 md:mr-3 text-center"
                type="submit"
                :disabled="isSubmitted"
                :class="isSubmitted ? 'opacity-75' : ''"
              >
                {{ isSubmitted ? "Adding User..." : "Add User" }}
              </button>
              <button
                class="flex-no-shrink text-gray-700 border-gray-700 border-2 py-2 px-4 rounded-full w-full mt-2 md:w-40 md:ml-3 md:mt-0"
                @click="showPopup = false"
                :disabled="isSubmitted"
                :class="isSubmitted ? 'opacity-75' : ''"
              >
                {{ isSubmitted ? "Please Wait..." : "Cancel" }}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
    <!-- edit User popup-->
    <div
      class="h-screen w-full flex items-center justify-center bg-teal-lightest fixed top-0 overflow-auto right-0 lg:business-modal-lg "
      v-if="showEditPopup"
    >
      <div
        class="h-screen w-full absolute flex items-center justify-center bg-modal"
      >
        <div
          class="bg-white rounded shadow pb-8 m-4 max-h-full text-center w-96"
        >
          <div class="mb-4 bg-gray-700 p-4 text-white text-base">
            Edit User
          </div>
          <form @submit.prevent="updateUser" class="px-4">
            <div class="items-center flex flex-col mt-7">
              <div class="flex flex-col mb-6 w-full mt-2">
                <input
                  class="border py-2 px-3 text-grey-darkest mt-1"
                  type="text"
                  placeholder="Name"
                  v-model="user.display_name"
                  name="user_displayname"
                  autocomplete="off"
                  required
                  id="user_displayname"
                />
                <input
                  class="border py-2 px-3 text-grey-darkest mt-1"
                  type="text"
                  placeholder="Username"
                  v-model="user.username"
                  name="user_username"
                  autocomplete="off"
                  required
                  id="user_username"
                />
                <select
                  required
                  class="form-select mt-1 block w-full capitalize px-2 py-3 border-gray-200 border-2"
                  v-if="checkRole(user)"
                  v-model="user.location_id"
                >
                  <option disabled selected value="title">
                    Select Location</option
                  >
                  <option
                    v-for="location in locations"
                    :key="location.id"
                    :value="location.id"
                  >
                    {{ location.location_name }}
                  </option>
                </select>
                <select
                  required
                  class="form-select mt-1 block w-full px-2 py-3 border-gray-200 border-2"
                  v-if="
                    (userHead.role === 'branch-manager' &&
                      user.role === 'branch-staff') ||
                      (userHead.role === 'business-admin' &&
                        user.role !== 'business-admin') ||
                      userHead.role === 'superadmin'
                  "
                  v-model="user.role"
                >
                  <option disabled selected value="title"> Select Role</option>
                  <option
                    v-for="role in roles[0].options"
                    :value="role.value"
                    :key="role"
                  >
                    {{ role.title }}
                  </option>
                </select>
              </div>
            </div>
            <div role="alert" class="my-3" v-if="errorMsg != ''">
              <div
                class="border border-red-400 rounded text-center bg-red-100 text-sm px-4 py-3 text-red-700"
              >
                <p>{{ errorMsg }}.</p>
              </div>
            </div>
            <div class="flex justify-center flex-col md:flex-row px-2">
              <button
                class="flex-no-shrink w-full text-white py-2 px-4 rounded-full bg-gray-700 md:w-40 md:mr-3 text-center"
                type="submit"
                :disabled="isSubmitted"
                :class="isSubmitted ? 'opacity-75' : ''"
              >
                {{ isSubmitted ? "Updating User..." : "Update User" }}
              </button>
              <button
                class="flex-no-shrink text-gray-700 border-gray-700 border-2 py-2 px-4 rounded-full w-full mt-2 md:w-40 md:ml-3 md:mt-0"
                @click="showEditPopup = false"
                :disabled="isSubmitted"
                :class="isSubmitted ? 'opacity-75' : ''"
              >
                {{ isSubmitted ? "Please Wait..." : "Cancel" }}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
    <!-- reset password popup -->
    <div
      class="h-screen w-full flex items-center justify-center bg-teal-lightest fixed top-0 overflow-auto right-0 lg:business-modal-lg "
      v-if="showResetPassPopup"
    >
      <div
        class="h-screen w-full absolute flex items-center justify-center bg-modal"
      >
        <div class="bg-white rounded shadow pb-8 m-4 max-h-full text-center">
          <div class="mb-4 bg-gray-700 p-4 text-white text-base">
            Reset User Password
          </div>
          <form @submit.prevent="resetPassword" class="px-4">
            <div class="items-center flex flex-col mt-7">
              <div class="text-lg self-start font-semibold">
                {{ user.display_name }}
              </div>
              <div class="text-base self-start my-4">
                <div class="text-gray-300 text-left">
                  Username:
                </div>
                <div class="text-left">{{ user.username }}</div>
              </div>
              <div class="flex flex-col mb-6 w-full mt-2">
                <input
                  class="border py-2 px-3 text-grey-darkest mt-1"
                  type="text"
                  placeholder="New Password"
                  v-model="user.password"
                  minlength="6"
                  name="user_password"
                  autocomplete="off"
                  required
                  id="user_password"
                />
              </div>
            </div>
            <div class="flex justify-center flex-col md:flex-row px-2">
              <button
                class="flex-no-shrink w-full text-white py-2 px-4 rounded-full bg-gray-700  md:mr-3 text-center"
                type="submit"
                :disabled="isSubmitted"
                :class="isSubmitted ? 'opacity-75' : ''"
              >
                {{ isSubmitted ? "Updating Password..." : "Update Password" }}
              </button>
              <button
                class="flex-no-shrink text-gray-700 border-gray-700 border-2 py-2 px-4 rounded-full w-full mt-2 md:w-40 md:ml-3 md:mt-0"
                @click="showResetPassPopup = false"
                :disabled="isSubmitted"
                :class="isSubmitted ? 'opacity-75' : ''"
              >
                {{ isSubmitted ? "Please Wait..." : "Cancel" }}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
    <!-- activating / deactivating account popup -->
    <div
      class="h-screen w-full flex items-center justify-center bg-teal-lightest fixed top-0 overflow-auto right-0 lg:business-modal-lg "
      v-if="showConfirmationPopup"
    >
      <div
        class="h-screen w-full absolute flex items-center justify-center bg-modal"
      >
        <div class="bg-white rounded shadow pb-8 m-4 max-h-full text-center">
          <div class="mb-4 bg-gray-700 p-4 text-white text-base">
            Are you sure to
            {{ user.status === "active" ? "deactivate" : "activate" }}
            <span class="text-blue-400"> {{ user.display_name }} </span>
          </div>
          <form @submit.prevent="updateUserStatus">
            <div class="flex justify-center flex-col md:flex-row px-2">
              <button
                class="flex-no-shrink w-full text-white py-2 px-4 rounded-full bg-gray-700 md:w-24 md:mr-3 text-center"
                type="submit"
                :disabled="isSubmitted"
                :class="isSubmitted ? 'opacity-75' : ''"
              >
                Yes
              </button>
              <button
                class="flex-no-shrink text-gray-700 border-gray-700 border-2 py-2 px-4 rounded-full w-full mt-2 md:w-24 md:ml-3 md:mt-0"
                @click="showConfirmationPopup = false"
                :disabled="isSubmitted"
                :class="isSubmitted ? 'opacity-75' : ''"
              >
                No
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import BusinessHeader from "./BusinessHeader";
import axios from "axios";
const firebase = require("../firebaseConfig");

export default {
  name: "UsersComponent",
  components: {
    BusinessHeader,
  },
  data: () => ({
    showPopup: false,
    showEditPopup: false,
    showResetPassPopup: false,
    showConfirmationPopup: false,
    locationCurrentId: "",
    business: {},
    errorMsg: "",
    users: [],
    isSubmitted: false,
    userName: "",
    allUsers: [],
    locations: [],
    usersCount: [],
    roles: [
      {
        role: "branch-manager",
        options: [
          {
            title: "Branch Staff",
            value: "branch-staff",
          },
        ],
      },
      {
        role: "business-admin",
        options: [
          {
            title: "Branch Manager",
            value: "branch-manager",
          },
          {
            title: "Branch Staff",
            value: "branch-staff",
          },
        ],
      },
      {
        role: "superadmin",
        options: [
          {
            title: "Business Admin",
            value: "business-admin",
          },
          {
            title: "Branch Manager",
            value: "branch-manager",
          },
          {
            title: "Branch Staff",
            value: "branch-staff",
          },
        ],
      },
    ],
    userHead: {},
    user: {
      uid: "",
      display_name: "",
      username: "",
      password: "",
      role: "title",
      status: "active",
      location_id: "title",
    },
  }),
  async mounted() {
    this.business.id = this.$route.params.business_id;
    this.userHead = await this.getUserInfo();
    this.getBusinessInfo();
    this.loadRoles();
    this.fetchUsers();
    this.getLocations();
  },
  methods: {
    loadRoles() {
      this.roles = this.roles.filter(
        (item) => item.role === this.userHead.role
      );
    },
    async getBusinessInfo() {
      await firebase.db
        .collection("businesses")
        .doc(
          this.userHead.role !== "superadmin"
            ? this.userHead.business_id
            : this.business.id
        )
        .onSnapshot((doc) => {
          this.business = {
            id: doc.id,
            ...doc.data(),
          };
        });
    },
    fetchUsers() {
      if (this.userHead.role === "business-admin") {
        firebase.db
          .collection("users")
          .where(
            "business_id",
            "==",
            this.userHead.role !== "superadmin"
              ? this.userHead.business_id
              : this.business.id
          )
          .onSnapshot({ includeMetadataChanges: true }, (docs) => {
            this.users = [];
            docs.forEach((doc) => {
              if (doc.data().business_id) {
                if (doc.id !== this.userHead.id) {
                  if (
                    (doc.data().location_id !== this.userHead.location_id &&
                      doc.data().role === "business-admin") ||
                    doc.data().location_id === this.userHead.location_id
                  ) {
                    this.users.push({
                      uid: doc.id,
                      ...doc.data(),
                    });
                  }
                }
              }
            });
            this.getLocations();
          });
      } else if (this.userHead.location_id) {
        firebase.db
          .collection("users")
          .where("location_id", "==", this.userHead.location_id)
          .where(
            "business_id",
            "==",
            this.userHead.role !== "superadmin"
              ? this.userHead.business_id
              : this.business.id
          )
          .onSnapshot({ includeMetadataChanges: true }, (docs) => {
            this.users = [];
            docs.forEach((doc) => {
              if (doc.data().business_id) {
                if (doc.id !== this.userHead.id) {
                  this.users.push({
                    uid: doc.id,
                    ...doc.data(),
                  });
                }
              }
            });
            this.getLocations();
          });
      } else {
        firebase.db
          .collection("users")
          .where(
            "business_id",
            "==",
            this.userHead.role !== "superadmin"
              ? this.userHead.business_id
              : this.business.id
          )
          .onSnapshot({ includeMetadataChanges: true }, (docs) => {
            this.users = [];
            docs.forEach((doc) => {
              if (doc.data().business_id) {
                if (doc.id !== this.userHead.id) {
                  this.users.push({
                    uid: doc.id,
                    ...doc.data(),
                  });
                }
              }
            });
            this.getLocations();
          });
      }
    },
    checkRole(user) {
      if (
        this.userHead.role === "branch-manager" &&
        user.role === "branch-staff"
      ) {
        return true;
      }
      if (
        this.userHead.role === "business-admin" &&
        user.role !== "business-admin"
      ) {
        return true;
      }
      if (this.userHead.role === "superadmin") {
        return true;
      }
      return false;
    },
    getUserInfo() {
      return new Promise((resolve, reject) => {
        firebase.db
          .collection("users")
          .doc(firebase.auth.currentUser.uid)
          .get()
          .then((user) => {
            resolve({ id: user.id, ...user.data() });
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    trimUserRole(role) {
      return role.replace("-", " ");
    },
    openModal() {
      this.user = {
        display_name: "",
        username: "",
        password: "",
        role: "title",
        status: "active",
        location_id: "title",
      };
      this.errorMsg = "";
      this.showPopup = true;
    },
    async getLocations() {
      await firebase.db
        .collection("businesses")
        .doc(
          this.userHead.role !== "superadmin"
            ? this.userHead.business_id
            : this.business.id
        )
        .collection("locations")
        .onSnapshot({ includeMetadataChanges: true }, (docs) => {
          this.locations = [];
          docs.forEach((doc) => {
            this.locations.push({
              id: doc.id,
              ...doc.data(),
            });

            this.users.forEach((user, index) => {
              this.locations.forEach((location) => {
                if (location.id == user.location_id) {
                  this.users[index].location_name = location.location_name;
                }
              });
            });
          });
        });
    },
    async addUser() {
      this.isSubmitted = true;
      const email = `${this.business.business_code}${this.user.username}@${process.env.VUE_APP_DOMAIN}`;

      const userExist = await axios({
        url: `${process.env.VUE_APP_FIREBASE_FUNCTIONS_URL}/checkUserExist`,
        method: "post",
        data: {
          email: email,
        },
      });

      console.info("user exist is", userExist);
      if (userExist.data) {
        this.errorMsg = "User already exist";
        this.isSubmitted = false;
        return false;
      }

      const location = this.locations.filter(
        (location) => location.id === this.user.location_id
      );

      const businessId =
        this.userHead.role !== "superadmin"
          ? this.userHead.business_id
          : this.business.id;

      if (this.userHead.role === "branch-manager") {
        this.user.location_id = this.userHead.location_id;
        this.user.role = "branch-staff";
        this.user.location_name = this.userHead.location_name;
      }

      const user = await firebase.db.collection("users").add({
        display_name: this.user.display_name,
        business_code: this.business.business_code,
        business_id: businessId,
        location_name:
          this.userHead.role === "branch-manager"
            ? this.userHead.location_name
            : location[0].location_name,
        username: this.user.username,
        location_id: this.user.location_id,
        role: this.user.role,
        status: this.user.status,
      });

      await axios({
        url: `${process.env.VUE_APP_FIREBASE_FUNCTIONS_URL}/createUser`,
        method: "POST",
        data: {
          uid: user.id,
          email: email,
          password: this.user.password,
        },
      });
      this.isSubmitted = false;
      this.showPopup = false;
    },
    editUser(user) {
      this.user = { ...user };
      this.userName = this.user.username;
      this.locationCurrentId = this.user.location_id;
      this.errorMsg = "";
      this.showEditPopup = true;
    },
    async updateUser() {
      this.isSubmitted = true;
      const email = `${this.business.business_code}${this.user.username}@${process.env.VUE_APP_DOMAIN}`;

      const userExist = await axios({
        url: `${process.env.VUE_APP_FIREBASE_FUNCTIONS_URL}/checkUserExist`,
        method: "post",
        data: {
          email: email,
        },
      });
      if (userExist && this.userName !== this.user.username) {
        this.errorMsg = "User already exist";
        this.isSubmitted = false;
        return false;
      }

      await firebase.db
        .collection("users")
        .doc(this.user.uid)
        .update({
          business_code: this.business.business_code,
          business_id:
            this.user.role !== "superadmin"
              ? this.user.business_id
              : this.business.id,
          display_name: this.user.display_name,
          ...(this.user.location_id && { location_id: this.user.location_id }),
          location_id: this.user.location_id,
          role: this.user.role,
          status: this.user.status,
          username: this.user.username,
        });

      await axios({
        url: `${process.env.VUE_APP_FIREBASE_FUNCTIONS_URL}/updateUser`,
        method: "POST",
        data: {
          uid: this.user.uid,
          email: email,
        },
      });

      this.isSubmitted = false;
      this.showEditPopup = false;
      this.getLocations();
    },
    openResetPasswordModal(user) {
      this.user = { ...user };
      this.showResetPassPopup = true;
    },
    async resetPassword() {
      this.isSubmitted = true;
      await axios({
        url: `${process.env.VUE_APP_FIREBASE_FUNCTIONS_URL}/resetUserPassword`,
        method: "post",
        data: {
          uid: this.user.uid,
          password: this.user.password,
        },
      });
      // this.getLocations();
      this.isSubmitted = false;
      this.showResetPassPopup = false;
    },
    changeUserStatus(user) {
      this.user = { ...user };
      this.showConfirmationPopup = true;
    },
    async updateUserStatus() {
      await firebase.db
        .collection("users")
        .doc(this.user.uid)
        .update({
          status: this.user.status === "active" ? "inactive" : "active",
        });
      // this.getLocations();
      this.showConfirmationPopup = false;
      this.getLocations();
      console.info("users are", this.users);
    },
  },
};
</script>
